<template>
 <commonTitle v-bind:datas="datas"></commonTitle>
  <div class="about ">
    <div class="clearfix pab30">
      <div class="w1200 b_fff pab30">
        <p class="font_28 mgt40">联系我们</p>
        <div class="b_border"></div>
        <div class="pat30 flex_box">
          <div class="pad50 addr_img">
            <div class="contact_text">
              <p>
                公司固话：<a href="tel:18030416518">028-87897798</a
                >（周一至周五上午9:00-12:00, 下午13:30-18:00）
              </p>
              <p>
                服务邮箱：<a href="mailto:chengp@roncoo.com" target="_blank"
                  >chengp@yntech.top</a>
              </p>
              <p>公司地址：成都市高新西区合作街道天骄路368号</p>
            </div>

            <div id="map" :style="{height:mapHeight}">
              <div id="allmap" ref="allmap"></div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import commonTitle from '@/components/common/commonTitle.vue'
	export default {
		name: "Contace_us",
		components: {
			commonTitle,
		},
		methods: {
			map() {
				let map = new window.BMap.Map(this.$refs.allmap); // 创建Map实例
				map.centerAndZoom(new window.BMap.Point(103.924265,30.758454), 15); // 初始化地图,设置中心点坐标和地图级别
				map.addControl(
					new window.BMap.MapTypeControl({
						// 添加地图类型控件
						mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP],
					})
				);
				map.setCurrentCity("成都"); // 设置地图显示的城市 此项是必须设置的
				map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
				var pt = new window.BMap.Point(103.924265,30.758454);
				var myIcon = new window.BMap.Icon(require("../assets/images/addr.png"), new window.BMap.Size(300,157));
				var marker2 = new window.BMap.Marker(pt,{icon:myIcon});  // 创建标注
				map.addOverlay(marker2);              // 将标注添加到地图中
			},
		},
		data() {
			return {
				datas:["contaceus"],
				mapHeight: 300 + "px",
			};
		},
		mounted() {
			window.scrollTo(0, 0)
			// this.map();
			window.onresize = () => {
				return (() => {
					this.mapHeight = window.innerHeight + 'px';
				})();
			};
		},
	};
</script>
<style lang="scss">
@import "@/assets/css/about.scss";

.map {
  width: 60%;
  height: 200px;
  }
#allmap {
  height: 100%;
  overflow: hidden;
}
.anchorBL{
  display:none;
}
</style>
